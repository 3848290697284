import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-to-post"
export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.linkTarget.addEventListener("click", this.scrollToPost.bind(this));
    this.updateLinkHref(this.linkTarget);
    document.addEventListener(
      "turbo:render",
      this.handleTurboRender.bind(this),
    );
  }

  disconnect() {
    this.linkTarget.removeEventListener("click", this.scrollToPost.bind(this));
    document.removeEventListener(
      "turbo:render",
      this.handleTurboRender.bind(this),
    );
  }

  updateLinkHref(link) {
    const accountFrame = this.findAccountFrame();
    link.href = accountFrame ? link.dataset.profilePath : link.dataset.feedPath;
  }

  findAccountFrame() {
    return [...document.querySelectorAll("turbo-frame[id^='account_']")].find(
      (frame) => frame.id.match(/^account_[a-zA-Z0-9_]+$/),
    );
  }

  scrollToPost(event) {
    const currentPostId = this.findCurrentPostId(event.currentTarget);
    const postsFrame = document.getElementById("posts");

    if (currentPostId) {
      event.preventDefault();

      const visitOptions = {
        frame: postsFrame ? "posts" : undefined,
        action: postsFrame ? undefined : "replace",
        visit_control: "reload",
      };

      Turbo.visit(event.currentTarget.href, visitOptions);
    }
  }

  findCurrentPostId(target) {
    return target.dataset.postId || null;
  }

  handleTurboRender() {
    const postId = sessionStorage.getItem("postId");
    if (postId) {
      setTimeout(() => {
        this.scrollToPostById(postId);
        sessionStorage.removeItem("postId");
      }, 50);
    }
  }

  scrollToPostById(postId) {
    const postElement = document.getElementById(`post_${postId}`);
    if (postElement) {
      postElement.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "defaultPreview", "uploadedPreview", "removeButton"];

  connect() {
    // No need to call resetPreview() here
  }

  promptFileSelection() {
    this.imageTarget.click();
  }

  previewImage() {
    const input = this.imageTarget.files[0];

    if (input) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedPreviewTarget.src = e.target.result;
        this.uploadedPreviewTarget.classList.remove('hidden');
        this.defaultPreviewTarget.classList.add('hidden');
        this.removeButtonTarget.classList.remove('hidden');
      };
      reader.readAsDataURL(input);
    }
  }

  resetPreview() {
    this.defaultPreviewTarget.classList.remove('hidden');
    this.uploadedPreviewTarget.classList.add('hidden');
    this.removeButtonTarget.classList.add('hidden');
  }

  removeImage() {
    this.imageTarget.value = "";
    this.resetPreview();
  }
}

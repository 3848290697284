import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expand-text"
export default class extends Controller {
  static targets = ["content", "seeMoreText"]

  toggleContent() {
    this.contentTarget.classList.toggle('line-clamp-3')
    this.seeMoreTextTarget.classList.toggle('hidden')
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal-form-submission"
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-start", () => {
      this.element.closest("[data-modal-target]").close();
    });
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", () => {
      this.element.closest("[data-modal-target]").close();
    });
  }
}
